import React, { useState } from 'react';
import './Register.css';
import logo from '../../assets/logo.png';
import api from '../../services/api';

export default function Register({ history }) {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatarBeingUploaded, setAvatarBeingUploaded] = useState(false);
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [error, setError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    if(!avatarLoaded) {
      setError('Você precisa aguardar sua foto carregar');
      return;
    }

    if(!name || !username || !password) {
      setError('Você precisa preencher todos os campos');
      return;
    }

    if(avatarUrl === '') {
      setError('Você precisa escolher uma foto');
      return;
    }

    try {
      const response = await api.post('/devs', {
        username,
        password,
        name,
        avatarUrl,
      });

      if(response.status !== 200) {
        console.log('Erro ao cadastrar usuário');
        return;
      }

      const { _id } = response.data;

      localStorage.setItem('user', _id);

      history.push(`/profiles`);
    } catch (error) {
      setError('Houve um erro ao cadastrar o usuário');
      console.log(error);
    }
  }

  const handleFileChange = async e => {
    await uploadFile(e.target.files[0]);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('avatar', file);

    try {
        setAvatarBeingUploaded(true);

        const response = await api.post('/devs/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const { url } = response.data;

        setAvatarUrl(url);
        setAvatarLoaded(true);

        setAvatarBeingUploaded(false);
    } catch (error) {
        setError('Houve um erro ao fazer o upload do arquivo');
        console.error('Houve um erro ao fazer o upload do arquivo:', error);
    }
  }

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <img src={logo} className="logo" alt="Cantamatch" />
        <input
          placeholder="Digite seu nome"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <input
          placeholder="Escolha um nickname"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Defina uma senha"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <label className="file-upload">
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
          />
          {avatarLoaded ? "Foto carregada" : avatarBeingUploaded ? "Carregando foto..." : "Escolha uma foto" }
        </label>
        <button className="btn-grad" type="submit">
          Cadastrar
        </button>
        <br/>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
}
