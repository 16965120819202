import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Login.css';

import logo from '../../assets/logo.png';

import api from '../../services/api';

export default function Login({ history }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  async function checkIfUserIsLoggedIn() {
    const user = localStorage.getItem('user');
    if (user) {
      history.push(`/profiles`);
    }
  }

  checkIfUserIsLoggedIn();

  async function handleSubmit(e) {
    try {
        e.preventDefault();

        const response = await api.post('/devs/login', {
            username,
            password,
        });

        if(response.status !== 200) {
            setError('Usuário ou senha inválidos');
            return;
        }

        const { _id } = response.data;

        localStorage.setItem('user', _id);

        history.push(`/profiles`);
    } catch (error) {
        setError('Houve um erro ao logar o usuário. Valide os dados e tente novamente');
        console.log(error);
    }
  }

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <img src={logo} className="logo" alt="Cantamatch" />
        <input
          placeholder="Digite seu nickname"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Digite sua senha"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <button className="btn-grad" type="submit">
          Login
        </button>
        <Link to="/register" className="new-account">
            <div>
                <br/>
                Criar nova conta
            </div>
        </Link>
        <br/>
        <br/>
        <div>
            {error && <p className="error">{error}</p>}
        </div>
      </form>
    </div>
  );
}
